




























































































































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      loading: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      headers: [
        { text: this.$t("TRUCK_ID"), value: "id" },
        { text: "Loại xe", value: "type" },
        { text: this.$t("VN_TIME"), value: "vn_time" },
        { text: "Nhân viên trực", value: "on_duty_user" },
        { text: "Nhân viên thưởng", value: "bonus_users" },
        { text: "Thưởng cả phòng", value: "bonus_department_applied" },
        { text: "Tiền thưởng", value: "bonus_value" },
        { text: "Nhân viên phạt", value: "punish_users" },
        { text: "Phạt cả phòng", value: "punish_department_applied" },
        { text: "Tiền phạt", value: "punish_value" },
        { text: "Ghi chú", value: "note" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      availableTypeMap: {
        tap_13m: "Tạp 13m",
        tap_9m6: "Tạp 9m6",
        tap_17m: "Tạp 17m",
        tap_cont: "Cont tạp",
        tap_cont_20: "Cont tạp 20",
        tap_cont_40: "Cont tạp 40",
        tx: "Tách xe",
        nx: "Nguyên xe",
        nx_13m: "Nguyên xe 13m",
        nx_17m: "Nguyên xe 17m",
        nx_air: "Air",
        nx_cb: "Nguyên cont",
        nx_l: "Nguyên cont lẻ",
        db_cont_20: "Cont 20 đặc biệt",
        db_cont_40: "Cont 40 đặc biệt",
      },
    };
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    month() {
      return moment(this.currentDate).startOf("month").unix();
    },
    startMonth() {
      return moment(this.startDate).startOf("month").unix();
    },
    endMonth() {
      return moment(this.endDate).endOf("month").unix();
    },
    startTime() {
      const m = moment(this.currentDate).startOf("month");
      return m.startOf("day").unix();
    },
    endTime() {
      const m = moment(this.currentDate).endOf("month");
      return m.endOf("day").unix();
    },
    formTitle() {
      return this.$t("EDIT");
    },
  },
  methods: {
    async initialize() {
      if (!this.currentDate) {
        return;
      }
      const startTime = this.startTime;
      const endTime = this.endTime;

      this.loading = true;
      const options: any = {
        limit: -1,
        filters: [
          {
            key: "vn_time",
            operator: ">=",
            value: startTime,
          },
          {
            key: "vn_time",
            operator: "<=",
            value: endTime,
          },
        ],
        extra: {
          on_duty: true,
        },
      };
      if (this.currentUser && this.currentUser.id) {
        options.extra.on_duty_user_id = this.currentUser.id;
      }
      const { items } = await apiClient.truckGetAll(options);
      items.forEach((item) => {
        item.bonus_user_ids = item.bonus_users.map((user) => user.id);
        item.punish_user_ids = item.punish_users.map((user) => user.id);
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.count++;
          totalItem.bonus_value += item.bonus_value || 0;
          totalItem.bonus_value -= item.punish_value || 0;
          return totalItem;
        },
        {
          count: 0,
          bonus_value: 0,
        }
      );

      this.items = items;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const result = await apiClient.truckUpdate(this.editedItem, [
        "on_duty_user_id",
        "bonus_department_applied",
        "bonus_value",
        "punish_department_applied",
        "punish_value",
        "note",
      ]);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");

    if (userManager.checkRole(["xnk"], true)) {
      const userInfo = userManager.getUserInfo();
      const user = {
        id: userInfo.sub,
        name: userInfo.name,
      };
      this.currentUser = user;
      this.users.push(user);
    } else {
      const { items } = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "xnk",
          },
        ],
      });
      items.unshift({
        name: "ALL",
      });
      for (const user of items) {
        this.users.push(user);
      }
    }

    await this.initialize();
  },
});
